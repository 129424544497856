import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
let that = window;
console.log(that);
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("../views/home1.vue"),
  // },
  // // 关于我们
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () => import("../views/about.vue"),
  // },
  // //  白皮书
  // {
  //   path: "/whitePaper",
  //   name: "whitePaper",
  //   component: () => import("../views/whitePaper.vue"),
  // },
  // // 如何加入BmileX生态建设
  // {
  //   path: "/join",
  //   name: "join",
  //   component: () => import("../views/join.vue"),
  // },
  // // 招募合作
  // {
  //   path: "/zhaop",
  //   name: "zhaop",
  //   component: () => import("../views/zhaop.vue"),
  // },






  //pc端首页
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("../views/home1.vue"),
  // },
 
  //移动端首页
  {
    path: "/",
    name: "HomeMobile",
    // component: () => this.mywindow.myfunction()?import('../views/home1.vue'):import('../views/home1Mobile.vue'),
    component: () => import("../views/home1.vue"),
  },

  {
    path: "/HomeNow",
    name: "HomeNow",
    // component: () => this.mywindow.myfunction()?import('../views/home1.vue'):import('../views/home1Mobile.vue'),
    component: () => import("../views/homeNow.vue"),
  },

  // 关于我们
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
  },
  //  白皮书
  {
    path: "/whitePaper",
    name: "whitePaper",
    component: () => import("../views/whitePaper.vue"),
  },
  // 如何加入BmileX生态建设
  {
    path: "/join",
    name: "join",
    component: () => import("../views/join.vue"),
  },
  // 招募合作
  {
    path: "/zhaop",
    name: "zhaop",
    component: () => import("../views/zhaop.vue"),
  },

  // App下载
  {
    path: "/download",
    name: "download",
    component: () => import("../views/download.vue"),
  }, 
    // App下载 - 移动端
    {
      path: "/down_m",
      name: "down_m",
      component: () => import("../views/down_m.vue"),
    }, 
];

const router = new VueRouter({
  routes,
});

export default router;
