import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import axios from 'axios'
// 配置baseURL
// axios.defaults.baseURL = '/apis/'

// import "./utils/flexible";
// import './utils/rem'
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

import "animate.css";

import { get, isPc } from "./utils/http";

Vue.config.productionTip = false;
Vue.use(Antd);
// Vue.use(axios)
Vue.prototype.$get = get;

let aa = window.navigator.userAgent;
console.log("aa", aa);

router.afterEach((to, from) => {
  console.log(to, from);
  if (to.path != "/") {
    document.getElementById("app").scrollTop = 0;
  }
  let arr = document.getElementsByClassName("ant-popover");
  if (arr.length) {
    // document.getElementsByClassName("ant-popover")[0].style.display = "none";
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
