import axios from 'axios'
export function get(url, params = {}) {

    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

// export function isPc(){
//     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent)) {
//       return false
//      }else{
//        return true;
//      }
//   }
